<template>
  <div class="page-header-title">
    <h3 class="m-b-10">{{ $t('integrations.title') }}</h3>
  </div>
  <div v-if="isDataLoading" class="position-relative">
    <spinner-component item-class="spinner-layout-gray"></spinner-component>
  </div>
  <div v-if="!isDataLoading">
    <div v-if="intergationsData.length === 0">
        {{ $t('integrations.emptyText') }}
    </div>

    <modal-wrapper ref="modalSettingsWrapper">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ $t('integrations.form.settings.title') }}</h5>
            <button
              @click="closeSettingsModal"
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <DynamicForm
              :schema="settingsForm"
              :button-data="settingsFormButton"
              :is-disabled="isSettingsFormLoading"
              :initial-field-value="initialSettingsFormData"
              form-class="justify-content-start"
              @submit="settingsFormHandler"
            >
            </DynamicForm>
          </div>
        </div>
      </div>
    </modal-wrapper>

    <modal-wrapper ref="modalCredentialsWrapper">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ $t('integrations.form.credentials.title') }}</h5>
            <button
              @click="closeCredentialsModal"
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <DynamicForm
              :schema="credentialsForm"
              :button-data="credentialsFormButton"
              :is-disabled="isCredentialsFormLoading"
              form-class="justify-content-start"
              @submit="credentialsFormHandler"
            >
            </DynamicForm>
          </div>
        </div>
      </div>
    </modal-wrapper>

    <div v-if="intergationsData.length > 0" class="row">
      <div
        v-for="integration in intergationsData"
        :key="integration.id"
        class="col-xl-4 col-md-6"
      >
        <div class="card-social card m-t-10">
          <div class="border-bottom card-body">
            <div class="row align-items-center justify-content-center">
                <div class="col-auto">
                  <img :src="integration.icon" :alt="integration.name" />
                </div>
                <div class="col text-right">
                  <h3>
                    {{ integration.name }}
                    <button
                      v-if="integration.connected"
                      type="button"
                      class="btn-icon btn-rounded btn btn-outline-secondary"
                      @click.prevent="() => openSettingsModal(integration.settings)"
                    >
                      <i class="icon feather icon-settings"></i>
                    </button>
                  </h3>
                  <h5 v-if="integration.connected" class="text-c-green mb-0">
                    {{ $t('integrations.connectedTitle') }}
                  </h5>
                  <h5 v-if="!integration.connected" class="mb-0 text-muted">
                    {{ $t('integrations.disconnectedTitle') }}
                  </h5>
                </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row align-items-center justify-content-center card-active">
              <modal-wrapper>
                <template v-slot="{ close }">
                  <confirmation-modal
                    :close="close"
                    :content="$t('integrations.deleteConfirmation')"
                    @confirm="() => removeIntegrationsConnectionHandler(integration.settings.uuid)"
                  ></confirmation-modal>
                </template>
                <template v-slot:trigger="{ open }">
                  <button
                    v-if="integration.connected"
                    @click.prevent="open"
                    class="btn btn-danger btn-sm"
                    :disabled="isRemoveLoading"
                  >
                    <i class="feather icon-trash mx-1"></i>
                    {{ $t('integrations.removeTitle') }}
                  </button>
                </template>
              </modal-wrapper>
              <button
                v-if="!integration.connected"
                @click.prevent="() => openCredentialsModal(integration.id)"
                class="btn btn-success btn-sm"
              >
                <i class="feather icon-plus mx-1"></i>
                {{ $t('integrations.addTitle') }}
              </button>
            </div>
          </div>
          <div class="card-body">
            <div class="row align-items-center justify-content-center card-active">
                <div class="col-12">
                  <h6 class="text-center">
                      <span class="text-muted">
                        {{ $t(`integrations.${integration.type}.description`) }}
                      </span>
                  </h6>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent, onMounted } from 'vue'
import DynamicForm from '../forms/DynamicForm.vue'
import { useIntegrationsInfo } from '@/application/composables/integrationsInfo'

export default {
  name: 'IntegrationsPage',
  components: {
    DynamicForm,
    'spinner-component': defineAsyncComponent(() => import('@/application/components/layout/SpinnerComponent.vue')),
    'confirmation-modal': defineAsyncComponent(() => import('@/application/components/modals/ConfirmationModal.vue')),
    'modal-wrapper': defineAsyncComponent(() => import('@/application/components/modals/ModalWrapper.vue')),
  },
  setup() {
    const {
      getIntegrationsData,
      isDataLoading,
      intergationsData,
      // Delete integration flow
      removeIntegrationsConnectionHandler,
      isRemoveLoading,
      // Credentials form
      credentialsForm,
      credentialsFormButton,
      credentialsFormHandler,
      openCredentialsModal,
      closeCredentialsModal,
      modalCredentialsWrapper,
      isCredentialsFormLoading,
      // Settings form
      settingsForm,
      settingsFormButton,
      settingsFormHandler,
      modalSettingsWrapper,
      initialSettingsFormData,
      isSettingsFormLoading,
      closeSettingsModal,
      openSettingsModal,
    } = useIntegrationsInfo()

    onMounted(async() => {
      await getIntegrationsData()
    })

    return {
      isDataLoading,
      intergationsData,
      // Delete integration flow
      removeIntegrationsConnectionHandler,
      isRemoveLoading,
      // Credentials form
      credentialsForm,
      credentialsFormButton,
      credentialsFormHandler,
      openCredentialsModal,
      closeCredentialsModal,
      modalCredentialsWrapper,
      isCredentialsFormLoading,
      // Settings form
      settingsForm,
      settingsFormButton,
      settingsFormHandler,
      modalSettingsWrapper,
      initialSettingsFormData,
      isSettingsFormLoading,
      closeSettingsModal,
      openSettingsModal,
    }
  },
}
</script>
