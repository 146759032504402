import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { trimValues } from '@/application/utils/trim.js'
import {
  getIntegrationsDataApi,
  sendIntegrationsCredentialsApi,
  sendIntegrationsSettingsApi,
  sendIntegrationsConnectionRemoveApi
} from '@/application/services/integrations'

export const useIntegrationsInfo = () => {
  const i18n = useI18n()
  const isDataLoading = ref(true)
  const isCredentialsFormLoading = ref(false)
  const isSettingsFormLoading = ref(false)
  const isRemoveLoading = ref(false)
  const intergationsData = ref([])
  const modalCredentialsWrapper = ref(null)
  const modalSettingsWrapper = ref(null)
  const integrationSelected = ref(null)
  const connectionSelected = ref(null)
  const initialSettingsFormData = ref({})

  const getIntegrationsData = () => {
    isDataLoading.value = true
    getIntegrationsDataApi().then(({ data }) => {
      isDataLoading.value = false
      intergationsData.value = data.data
    })
  }

  const removeIntegrationsConnectionHandler = (id) => {
    isRemoveLoading.value = true
    initialSettingsFormData.value = {}
    sendIntegrationsConnectionRemoveApi(id)
      .then(async({ data }) => {
        isRemoveLoading.value = false
        await getIntegrationsData()
      })
      .catch(err => {
        console.log(err)
        isRemoveLoading.value = false
      })
  }

  const settingsFormHandler = (data) => {
    isSettingsFormLoading.value = true
    data.connection = connectionSelected.value
    sendIntegrationsSettingsApi(data)
      .then(async({ data }) => {
        isSettingsFormLoading.value = false
        modalSettingsWrapper.value.close()
        await getIntegrationsData()
      })
      .catch(err => {
        console.log(err)
        isSettingsFormLoading.value = false
      })
  }
  const settingsFormButton = computed(() => ({
    wrapper: 'justify-content-center',
    class: 'btn btn-primary shadow-2 mt-4',
    text: i18n.t('integrations.form.settings.sendButton'),
  }))
  const settingsForm = computed(() => [
    {
      label: i18n.t('integrations.form.settings.unapproved_content'),
      name: 'is_send_unapproved_content',
      as: 'input',
      type: 'checkbox',
      cols: 'col-12',
      inputClass: 'form-control',
    },
    {
      label: i18n.t('integrations.form.settings.unable_to_deliver'),
      name: 'is_send_unable_to_deliver',
      as: 'input',
      type: 'checkbox',
      cols: 'col-12',
      inputClass: 'form-control',
    },
    {
      label: i18n.t('integrations.form.settings.media_content'),
      name: 'is_send_mms_if_media_content',
      as: 'input',
      type: 'checkbox',
      cols: 'col-12',
      inputClass: 'form-control',
    },
    {
      label: i18n.t('integrations.form.settings.camel_snake'),
      name: 'is_snake_response',
      as: 'input',
      type: 'checkbox',
      cols: 'col-12',
      inputClass: 'form-control',
    },
  ])
  const credentialsFormHandler = (data) => {
    isCredentialsFormLoading.value = true
    const formatted = trimValues(data)
    formatted.integration = integrationSelected.value
    sendIntegrationsCredentialsApi(formatted)
      .then(({ data }) => {
        isCredentialsFormLoading.value = false
        connectionSelected.value = data.connection
        modalCredentialsWrapper.value.close()
        modalSettingsWrapper.value.open()
      })
      .catch(err => {
        console.log(err)
        isCredentialsFormLoading.value = false
      })
  }
  const credentialsFormButton = computed(() => ({
    wrapper: 'justify-content-center',
    class: 'btn btn-primary shadow-2 mt-4',
    text: i18n.t('integrations.form.credentials.sendButton'),
  }))
  const credentialsForm = computed(() => [
    {
      label: i18n.t('integrations.form.credentials.account_sid'),
      name: 'account_sid',
      as: 'input',
      type: 'text',
      rules: 'required|min:34|max:64',
      cols: 'col-12',
      inputClass: 'form-control',
    },
    {
      label: i18n.t('integrations.form.credentials.auth_token'),
      name: 'auth_token',
      as: 'input',
      type: 'text',
      rules: 'required|min:32|max:64',
      cols: 'col-12',
      inputClass: 'form-control',
    },
    {
      label: i18n.t('integrations.form.credentials.phone_number'),
      name: 'from_phone',
      as: 'input',
      type: 'text',
      rules: 'required|phone',
      cols: 'col-12',
      inputClass: 'form-control',
    },
  ])

  return {
    getIntegrationsData,
    isDataLoading,
    intergationsData,
    // Delete integration flow
    removeIntegrationsConnectionHandler,
    isRemoveLoading,
    // Credentials form
    credentialsForm,
    credentialsFormButton,
    credentialsFormHandler,
    modalCredentialsWrapper,
    isCredentialsFormLoading,
    closeCredentialsModal: () => {
      modalCredentialsWrapper.value.close()
    },
    openCredentialsModal: (integration) => {
      integrationSelected.value = integration
      modalCredentialsWrapper.value.open()
    },
    // Settings form
    settingsForm,
    settingsFormButton,
    settingsFormHandler,
    modalSettingsWrapper,
    isSettingsFormLoading,
    initialSettingsFormData,
    closeSettingsModal: () => {
      modalSettingsWrapper.value.close()
    },
    openSettingsModal: (settings) => {
      if (settings) {
        connectionSelected.value = settings.uuid
        initialSettingsFormData.value = settings
      }
      modalSettingsWrapper.value.open()
    },
  }
}
